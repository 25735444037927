/*/
THEMING
-------

https://blog.prototypr.io/css-variables-90cc4cdf41e9
https://bitsofco.de/css-environment-variables/
https://www.browserlondon.com/blog/2019/01/15/css-variables-theming/
https://medium.com/@NateBaldwin/theming-with-css-variables-d7acddd3a699
https://medium.com/front-end-weekly/dynamic-theme-with-css-variables-f1fed123a07f
https://getbootstrap.com/docs/4.0/getting-started/theming/
https://blog.logrocket.com/how-to-create-better-themes-with-css-variables-5a3744105c74/
https://dev.to/idoshamun/theming-with-css-variables-322f
https://www.digitalocean.com/community/tutorials/css-theming-custom-properties
https://semantic-ui.com/usage/theming.html
https://css-tricks.com/css-custom-properties-theming/
/*/

/*/ Spacers /*/
$spacer-1: 4px;
$spacer-2: 8px;
$spacer-3: 16px;
$spacer-4: 24px;
$spacer-5: 32px;
$spacer-6: 64px;
$spacer-7: 96px;
$spacer-8: 128px;

/*/ Breakpoints /*/
$desktop-breakpoint: 960px;
$tablet-breakpoint: 820px;
$mobile-breakpoint: 400px;

/*/ dashboard vars /*/
$main-topnav-height: 40px;
$main-sidebar-width: 180px;
$main-sidebar-mini-width: 50px;
$main-footer-height: 18px;

/*/ app vars /*/
$topnav-height: 80px;
$topnav-right-menu-item-height: 32px;
$topnav-right-menu-item-count: 3;
$topnav-right-menu-height: calc(#{$topnav-right-menu-item-count} * #{$topnav-right-menu-item-height});
$topnav-height-desktop: 140px;
