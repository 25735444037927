/*/ Design tokens /*/
@import 'design-tokens/cold_vars';
@import 'design-tokens/hot_vars';
/*/ Mixins /*/
@import 'mixins';

body
{
    @include background-pattern('square-versatiles');
}

.segment.latest-posts
{
    display: grid;
    grid-template-columns: repeat(auto-fit, 1fr);
    grid-auto-rows: min-content;
    gap: 5px;

    article
    {
        position: relative;
        padding: $spacer-2 $spacer-2;
        border: 1px solid #fff;
        border-radius: var(--border-radius-m);
        transition: transform .2s ease;
    }

    article:hover:not(:nth-child(1))
    {
        transform: translateY(-4px);
    }

    article:nth-child(even)
    {
        background-color: #ede7e7;
    }

    article:nth-child(odd)
    {
        background-color: #e6f5ca;
    }

    article:nth-child(1)
    {
        background-color: #d2f8f0;
    }

    .article.meta
    {
        margin: 0em $spacer-1;
    }

    article .category
    {
        margin: $spacer-2 0em;
        padding: $spacer-1 $spacer-2;
        border-radius: var(--border-radius-s);
        background-color: #ddd;
        display: inline-block;
        width: auto;
    }

    .global-link
    {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 1;
        text-decoration: none;
    }

    @media only screen and (min-width: $desktop-breakpoint)
    {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(20vw, 1fr));
        grid-auto-rows: min-content;
        grid-auto-flow: dense;
        gap: 0.8rem;
        padding: $spacer-3 $spacer-5;

        article
        {
            padding: $spacer-2 $spacer-3;
        }

        article:nth-child(1),
        article:nth-child(5),
        article:nth-child(8),
        article:nth-child(3n + 1)
        {
            grid-column: span 2;
        }
    }
}

.segment.latest-posts-pagination
{
    display: flex;
    justify-content: center;
    align-items: center;

    padding: $spacer-5 $spacer-3;
}

/*/
Subscribe
/*/
.segment.subscribe
{
    padding: $spacer-3 $spacer-2;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    @media only screen and (min-width: $desktop-breakpoint)
    {
        padding: $spacer-3 $spacer-5;
    }
}

.form.subscribe
{
    --color: #2b394e;
    --background-color: #ededf1;
    --button-color: #646eff;
    --border-radius: 60px;
    --height: 60px;
    --button-width: 80px;
    --min-width: 60vw;
    --max-width: 80vw;

    font-size: 0.72rem;

    @media only screen and (min-width: $tablet-breakpoint)
    {
        --border-radius: 60px;
        --height: 60px;
        --button-width: 100px;
        --min-width: 200px;
        --max-width: 400px;

        font-size: 0.86rem;
    }

    @media only screen and (min-width: $desktop-breakpoint)
    {
        --border-radius: 100px;
        --height: 100px;
        --button-width: 160px;
        --min-width: 260px;
        --max-width: 520px;

        font-size: 1rem;
    }

    position: relative;
    width: 100%;
    margin-top: $spacer-3;
    min-width: var(--min-width);
    max-width: var(--max-width);
    height: var(--height);
    border-radius: var(--border-radius);
    background-color: var(--background-color);
}

.form.subscribe:focus-within
{
    border: 1px solid var(--button-color);
}

.form.subscribe input[type='email']
{
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: calc(var(--button-width) + 20px);
    width: calc(100% - var(--button-width) + 20px);
    display: block;
    height: 100%;
    border-radius: var(--border-radius);

    color: var(--color);
    outline: 0;
    border: 0;
    background-color: transparent;
    padding: $spacer-2 $spacer-3;
    padding-right: 36px;
    box-shadow: none;
    transition: width .3s ease .2s;
}

.form.subscribe button[type='submit']
{
    --color: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: var(--button-width);
    height: calc(var(--height) - 20px);
    border-radius: var(--border-radius);
    z-index: 1;
    background-color: var(--button-color);
    color: var(--color);
    cursor: pointer;
    outline: 0;
    border: 0;
    margin: 10px 10px;

    -webkit-appearance: button;
    appearance: button;
}
