:root
{

    /*/ Font /*/
    --font-family: Lato, Roboto, sans-serif;
    --font-weight: normal;
    --font-size: 1rem; /*/ 16px /*/
    --line-height: 1.6;

    /*/ Header XL /*/
    --font-header-xl-family: Lato, Roboto, sans-serif;
    --font-header-xl-weight: bold;
    --font-header-xl-size: 2.75rem; /*/ 44px /*/
    /*/ Header L /*/
    --font-header-l-family: Lato, Roboto, sans-serif;
    --font-header-l-weight: bold;
    --font-header-l-size: 2.125rem; /*/ 34px /*/
    /*/ Header M /*/
    --font-header-m-family: Lato, Roboto, sans-serif;
    --font-header-m-weight: bold;
    --font-header-m-size: 1.5rem; /*/ 24px /*/
    /*/ Paragraph L /*/
    --font-paragraph-l-family: Lato, Roboto, sans-serif;
    --font-paragraph-l-weight: normal;
    --font-paragraph-l-size: 1.125rem; /*/ 18px /*/
    /*/ Paragraph M /*/
    --font-paragraph-m-family: Lato, Roboto, sans-serif;
    --font-paragraph-m-weight: normal;
    --font-paragraph-m-size: 1rem; /*/ 16px /*/

    /*/ Border radii /*/
    --border-radius-s: 4px;
    --border-radius-m: 8px;

    /*/ Box shadow /*/
    --shadow-0: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    --shadow-1: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    --shadow-2: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    --shadow-3: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    --shadow-4: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    --shadow-5: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}

// @media (prefers-color-scheme: dark)
// {
//     :root
//     {
//         /*/ Others /*/
//         --color-lighter: #FFFFFF;
//         --color-light: #e2e1e1;
//         --color-darker: #030303;
//         --color-dark: #262626;

//         /*/ Foreground /*/
//         --color-foreground-light: #f7f7ff;
//         --color-foreground-lighter: #fffffc;
//         --color-foreground-main: #c9d1d9;
//         --color-foreground-dark: #fef9ef;
//         --color-foreground-darker: #fdfffc;
//         /*/ Background /*/
//         --color-background-light: #293241; /*/ Xiketic /*/
//         --color-background-lighter: #24272b;
//         --color-background-main: #0d1117;
//         --color-background-dark: #101010;
//         --color-background-darker: #111111;
//         /*/ Border color /*/
//         --color-border-color-main: #21262d;
//     }
// }
